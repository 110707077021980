@import "styles/variables.scss"; @import "styles/colours.scss"; @import "styles/mixins.scss"; @import "styles/typography.scss";
@import 'buttons';

.button {
  display: flex;
  align-items: center;
  padding: 0.125rem;
  color: $grey-900;
  gap: 0.25rem;

  span {
    @include text-normal;
    @include font-secondary;

    color: $grey-900;
  }
}

.modal {
  display: flex;
  flex-direction: column;
  padding: 0 1.5rem;
  gap: 0.5rem;

  .icon {
    margin-bottom: 1rem;
  }

  .button--dismiss {
    @include button-plain;
    justify-content: center;
    padding: 0.875rem 0.75rem;

    border: 1px solid $primary-pink;
    border-radius: 6.25rem;
    margin-top: 1.5rem;
  }
}

.header {
  @include text-xl;
  @include text-bold;

  color: $grey-900;
  text-align: center;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  text-align: center;
}

.help-icon {
  filter: $primary-pink-filter;
}

.content {
  display: grid;
  gap: $spacing-xs;
  text-align: center;

  p {
    @include text-sm;
    @include text-bold;
    color: $grey-800;
  }

  a {
    @include text-bold;

    color: $primary-pink;
  }
}

.tooltip-link a {
  @include text-bold;

  color: $primary-pink;
}

.tooltip-modal-overlay {
  display: flex;
  justify-content: center;
}
