@import "styles/variables.scss"; @import "styles/colours.scss"; @import "styles/mixins.scss"; @import "styles/typography.scss";
.container {
  position: relative;
  min-height: 950px;
  background-color: $grey-100;
  overflow-x: clip;

  @include screen-size('small') {
    min-height: 800px;
  }

  @include screen-size('medium') {
    height: 100%;
    min-height: 720px;
  }

  &::after {
    position: absolute;
    z-index: 0;
    top: 40rem;
    left: -28rem;
    display: block;
    width: 2350px;
    height: 1180px;
    background-image: url('https://assets.leaseloco.com/homepage/xl-confetti.svg');
    background-repeat: no-repeat;
    background-size: cover;
    content: '';

    @include screen-size('small') {
      top: 42rem;
      left: calc(50% - 1175px);
    }

    @include screen-size('medium') {
      top: 32.5rem;
      left: calc(50% - 1175px);
    }

    @include screen-size('large') {
      top: 30rem;
      left: calc(50% - 1175px);
    }

    @include screen-size('extra-large') {
      top: 28rem;
    }
  }
}

.grid {
  display: flex;
  max-width: 1320px;
  flex-direction: column;
  margin: 0 1.5rem;
  gap: 1rem;

  @include screen-size('medium') {
    flex-direction: row;
  }

  @include screen-size('custom', 1352px) {
    margin: 0 auto;
  }
}

.column {
  position: relative;
  z-index: 5;
  display: flex;
  overflow: hidden;
  width: 100%;
  margin: 0 0 1rem;
  margin-bottom: 1rem;
  text-align: left;

  @include screen-size('medium') {
    max-height: 35rem;
  }
}

.heading {
  @include display-lg;
  @include text-bold;
  @include font-primary;

  color: $grey-900;
}

.text {
  @include text-lg;
  @include text-normal;
  @include font-primary;

  color: $grey-800;

  p:first-of-type {
    margin-bottom: 1.35rem;
  }

  span {
    color: $grey-900;
  }

  a {
    color: $primary-pink;
  }
}

.has-no-mobile-margin {
  margin: 0;

  @include screen-size('medium') {
    margin: 0 1rem 0.875rem;
  }

  @include screen-size('custom', 1352px) {
    margin: 0 auto 0.875rem;
  }
}

.data-points {
  display: flex;
  flex-wrap: nowrap;
  padding: 0.5rem 1.5rem 0;
  gap: 1rem;
  overflow-x: scroll;
  scroll-snap-type: x proximity;
  scrollbar-width: none;

  @include screen-size('medium') {
    overflow: hidden;
    flex-wrap: wrap;
    padding: 4.5rem 0 0;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.data {
  display: flex;
  width: 19rem;
  flex-direction: column;
  flex-shrink: 0;
  padding: 1.5rem;
  border-radius: 1.5rem;
  background-color: $white;
  gap: 0.5rem;

  @include screen-size('medium') {
    width: 14rem;
    padding: 0;
    border: 0;
    background-color: transparent;
  }

  @include screen-size('extra-large') {
    width: 17rem;
  }

  p {
    @include display-sm;
    @include text-bold;
    @include font-primary;

    color: $primary-500;

    &:last-of-type {
      @include text-lg;
      @include text-normal;

      color: $grey-900;
    }
  }
}

.is-visible {
  overflow: visible;
}

.vehicle {
  position: relative;
  z-index: 5;
  padding-top: 0.75rem;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  text-align: left;

  @include screen-size('medium') {
    padding: 0.75rem 0 0;
  }
}

.vehicle-data {
  position: relative;
  z-index: 5;
  padding-top: 0;

  @include screen-size('medium') {
    padding-top: 3.75rem;
  }

  a {
    @include text-md;
    @include text-bold;
    @include font-secondary;

    display: flex;
    align-items: center;
    color: $primary-pink;
    filter: $primary-pink-filter;
    gap: 0.375rem;
  }
}

.vehicle-name {
  @include text-lg;
  @include text-bold;
  @include font-primary;

  margin-top: 1rem;
  color: $grey-900;
}

.vehicle-derivative {
  @include text-sm;
  @include text-bold;
  @include font-secondary;

  margin-bottom: 0.5rem;
  color: $grey-800;
}

.vehicle-image {
  position: absolute;
  z-index: 0;
  top: 5rem;
  left: 4rem;
  width: 522px;
  height: 348px;
  transform: none;

  @include screen-size('small') {
    left: 50%;
  }

  @include screen-size('medium') {
    top: 0;
    left: 0;
    width: 945px;
    height: 630px;
  }
}

.desktop-margin-top {
  @include screen-size('medium') {
    margin-top: 1.5rem;
  }
}

.score {
  @include text-bold;

  scale: 1.414;
}
